<template>
  <div class="content content_in">
    <div class="d-flex">
    <h1 class="title-h2">{{ $t("cpl_rates.title") }}</h1>
    <input class="w-50 ml-auto" type="text" :placeholder="$t('search')" v-model="searchByCountry">
    </div>
    <div class="c-table mb-3">
      <vue-good-table
        styleClass="table vgt-table sortEnable tablesorter tablesorter-bootstrap"
        :columns="columns"
        :rows="filteredRows"
        :line-numbers="true"
        :sort-options="{
          enabled: true,
          multipleColumns: true,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <span class="cursor-pointer">
            {{ formatLabel(props.column.label) }}
          </span>
        </template>
        <template slot="table-row" slot-scope="props">
          <div class="country" v-if="props.column.field === checkCountry">
            <gb-flag
              v-if="props.row.iso"
              width="24px"
              height="16px"
              :code="props.row.iso"
            />
            <span class="flag-text">
              {{
                $i18n.locale === "ru"
                  ? props.row.country_name_ru
                  : props.row.country_name_en
              }} - ({{props.row.iso}})
            </span>
          </div>
          <div class="country" v-if="props.column.field === 'mobile_price'">
            <b>$ {{ props.row.mobile_price }}</b>
          </div>
          <div class="country" v-if="props.column.field === 'pc_price'">
            <b>$ {{ props.row.pc_price }}</b>
          </div>
        </template>
        <div slot="emptystate">
          {{ $t("any_results") }}
        </div>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.scss";
import DashboardService from "@/api/dashboardService";
import moment from "moment";

export default {
  name: "Top",
  beforeMount() {
    document.title = `Partners.House | ${this.$t("top_countries")}`;
  },
  beforeUpdate() {
    document.title = `Partners.House | ${this.$t("top_countries")}`;
  },
  data() {
    return {
      searchByCountry:'',
      columns: [
        {
          label: "dashboard.table.country",
          field: "country_name",
        },
        {
          label: "cpl_rates.table.mobile",
          field: "mobile_price",
          type: "price",
          sortFn: this.sortFn,
        },
        {
          label: "cpl_rates.table.pc",
          field: "pc_price",
          type: "price",
          sortFn: this.sortFn,
        },
      ],
      rows: [],
    };
  },
  mounted() {
    this.getAllCountries();
    this.columns[0].field = this.checkCountry;
  },
  watch: {
    "$i18n.locale": function () {
      this.columns[0].field = this.checkCountry;
    },
  },
  computed: {
    filteredRows(){
      let countryName = this.$i18n.locale == "en" ? 'country_name_en':'country_name_ru';

      if (this.searchByCountry === '') return this.rows
      return this.rows.filter(row => {
        return row[countryName].toLowerCase()
        .includes(this.searchByCountry.toLocaleLowerCase()) || 
        row.iso.toLocaleLowerCase().includes(this.searchByCountry.toLocaleLowerCase())
      })
    },
    checkCountry() {
      return this.$i18n.locale === "ru" ? "country_name_ru" : "country_name_en";
    },
  },

  methods: {
    getAllCountries() {
      DashboardService.getCPLRates().then((res) => {
        if (res.status === 200) {
          this.rows = res.data;
        }
      });
    },
    formatLabel(el) {
      return this.$t(el);
    },
    moment: (e) => {
      return moment(e);
    },
    sortFn(x, y) {
      return Number(x) - Number(y);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-table ::v-deep th.sorting-desc {
  button:after {
    display: none;
  }
}

.c-table ::v-deep th.sorting-asc {
  button:before {
    display: none;
  }
}

.c-table ::v-deep th.sortable button {
  margin-left: -2px;
  &:after,
  &:before {
    left: 0;
  }
}
</style>

